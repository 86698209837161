aside.aside {
    width: $aside-width;
    left: -$aside-width;
    background: $aside-background-color;
    position: fixed;
    top: 0;
    z-index: 40;
    height: 100vh;
    padding: 0;
    box-shadow: none;
    @include transition(left);

    .aside-tools {
        display: flex;
        flex-direction: row;
        width: 100%;
        color: $aside-tools-color;
        line-height: $navbar-height;
        height: $navbar-height;
        padding-left: $default-padding * 0.5;
        flex: 1;
    }

    .menu-list li a {
        border-left: 4px solid transparent;

        &.is-active {
            border-left: 4px solid $primary;
        }
    }

    .menu-list {
        li {
            ul {
                display: none;
                border-left: 0;
                background-color: darken($base-color, 2.5%);
                padding-left: 0;
                margin: 0 0 $default-padding * 0.5;

                li {
                    a {
                        padding: $default-padding * 0.5 0 $default-padding * 0.5 $default-padding * 0.5;
                        font-size: $aside-submenu-font-size;

                        &.has-icon {
                            padding-left: 0;
                        }
                        &.is-active {
                            &:not(:hover) {
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    .menu-label {
        padding: 0 $default-padding * 0.5;
        margin-top: $default-padding * 0.5;
        margin-bottom: $default-padding * 0.5;
    }

    .menu-list .icon {
        width: 3rem;
    }
}

.has-aside-mobile-expanded aside.aside {
    left: 0;
}

@include desktop {
    aside.aside {
        transition: initial;
        left: 0;
    }
}
