@import "bulma/sass/utilities/_all.sass";
@import "../../common";

.footer {
  background-color: $secondary;
  flex-shrink: 0;
  margin-bottom: var(--legal-banner-height);

  a {
    color: white;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footerLayout {
  @include mobile {
    display: flex;
    flex-direction: column;
  }
}

.logoFooterImage {
  display: inline-block !important;
  width: 80px;
  height: 24px;
  background: center / contain no-repeat url("/logo.svg");
  filter: invert(1);
}

.policies {
  margin-bottom: 2rem;
}

.copyrightNotice {
  opacity: 0.5;
}

.footerLogo {
  @include mobile {
    order: 0;
  }
}

.footerLegal {
  font-weight: 100;

  @include mobile {
    margin-top: 2rem;
    order: 2;
  }
}

.footerContact {
  @include mobile {
    order: 1;
  }
}
