body {
    @include transition(padding-left);
}

.has-aside-expanded {
    body,
    #root,
    .dash-navbar {
        width: 100vw;
    }

    body {
        padding-top: $navbar-height;
    }
}

.has-aside-mobile-expanded body {
    padding-left: $aside-width;
}

.dash-navbar {
    padding-left: 0;
    @include transition(padding-left);
}

.has-aside-mobile-expanded .dash-navbar {
    padding-left: $aside-width;
}

@include desktop {
    .has-aside-expanded {
        body,
        #root,
        .dash-navbar {
            width: initial;
        }
    }

    body {
        transition: initial;
    }

    .dash-navbar {
        padding-left: calc(#{$aside-width} + 1.8rem);
        padding-right: 1.8rem;
        transition: initial;
    }

    .has-aside-expanded body {
        padding-left: $aside-width;
    }
}
