.progressBar {
    max-width: 5rem;
}

.header1 {
    width: 3rem;
}
.header3,
.header6 {
    width: 8rem;
}

.header4 {
    width: 8rem;
}

.header5 {
    width: 6rem;
}

.remove {
    text-decoration: underline;
    text-decoration-style: dotted;
}

.externalLinkIcon {
    font-size: 0.8rem;
}
