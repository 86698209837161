@import 'bulma/sass/utilities/_all.sass';


.pageContainer {
    background: #f8f8f8;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.contentContainer {
    flex: 1;
}

@include mobile() {
    .pageContainer {
        background: white;
    }
}