.itemGroup {

    &.disabled {
        opacity: 0.5;
    }

    &.disabled .itemFormat {
        cursor: wait;
    }

    .itemFormat {
        cursor: pointer;
        user-select: none;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-top: 1px solid #ccc;
    
        &:hover {
            background: rgb(240, 240, 240);
        }

        &:active {
            background: darken(rgb(240, 240, 240), 3%);
        }

        &:first-of-type {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-top: 1px solid #ccc;
        }

        &:last-of-type {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom: 1px solid #ccc;
        }
    }
}