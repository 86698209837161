/* We'll need some initial vars to use here */
@import "bulma/sass/utilities/initial-variables";

@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600&subset=latin");

/* Base: Size  */
$size-base: 1rem;
$default-padding: $size-base * 1.5;

/* Disable hamburger menu */
$navbar-breakpoint: 0;

/* Default font */
$family-primary: Jost, $family-sans-serif;

/* Base color */
$base-color: #2e323a;
$base-color-light: rgba(24, 28, 33, .06);

/* General overrides */
$primary: #0075ff;
$secondary: #242424;
$light-border: 1px solid $base-color-light;
$hr-height: 1px;

/* Aside: Bulma override */
$menu-item-radius: 0;
$menu-list-link-padding: $size-base * .5 0;
$menu-label-color: lighten($base-color, 25%);
$menu-item-color: lighten($base-color, 30%);
$menu-item-hover-color: $white;
$menu-item-hover-background-color: darken($base-color, 3.5%);
$menu-item-active-color: $white;
$menu-item-active-background-color: lighten($base-color, 3.5%);

/* Aside: specifics */
$aside-width: $size-base * 17;
$aside-mobile-width: $size-base * 15;
$aside-icon-width: $size-base * 3;
$aside-submenu-font-size: $size-base * .95;
$aside-box-shadow: none;
$aside-background-color: $base-color;
$aside-tools-color: $white;

/* Title Bar: specifics */
$title-bar-color: $grey;
$title-bar-active-color: $black-ter;

/* Hero Bar: specifics */
$hero-bar-background: $white;

/* Card: Bulma override */
$card-shadow: none;
$card-header-shadow: none;

/* Card: specifics */
$card-border: 1px solid $base-color-light;
$card-header-border-bottom-color: $base-color-light;

/* Modal: Bulma override */
$modal-background-background-color: rgba(118, 155, 180, 0.7);

/* Table: Bulma override */
$table-head-cell-border-width: 0 0 1px;
$table-cell-border: 1px solid $base-color-light;