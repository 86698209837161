@import "bulma/sass/utilities/_all.sass";

.jobProgressBar {
    display: inline-block;
    vertical-align: baseline;
    width: 2.5rem;
    height: 0.5rem;
}

table.jobsTable {
    thead {
        tr {
            text-align: center;

            th.iconCol {
                width: 2rem;
            }

            th.filenameCol {
                text-align: left;
            }

            th.submittedCol {
                width: 9rem;
            }

            th.ownerCol {
                width: 14rem;
            }

            th.durationCol {
                width: 5rem;
            }

            th.statusCol {
                width: 5rem;
            }

            th.costCol {
                width: 5rem;
            }
        }
    }

    tbody {
        tr {
            text-align: center;

            &.deleted {
                opacity: 0.7;
            }

            td.filenameCol {
                text-align: left;
            }

            td.ownerCol {
                color: gray;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            @include desktop() {
                td.ownerCol {
                    max-width: 14rem;
                }
            }
        }
    }
}

.empty {
    color: gray;
}

@include mobile() {
    .tooltip {
        // copied from has-tooltip-left but applied conditionally in the media query
        &::before {
            top: auto !important;
            right: auto !important;
            bottom: 50% !important;
            left: -5px !important;
            transform: translate(-100%, 50%) !important;
        }

        &::after {
            top: auto !important;
            right: auto !important;
            bottom: 50% !important;
            left: 0 !important;
            margin-top: auto !important;
            margin-right: auto !important;
            margin-bottom: -6px !important;
            margin-left: -5px !important;
            border-color: transparent transparent transparent rgba(74, 74, 74, 0.9) !important;
        }
    }
}
