@import "../../_common.scss";

.blur {
    filter: blur(8px);
}

.overlay {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0 1.5rem 1.5rem 0;

    p > a {
        color: $primary;
        text-decoration: underline;
        text-decoration-style: dotted;
        text-underline-position: from-font;

        :visited {
            color: $primary;
        }
    }
}

.registerIcon {
    background-image: url("../../assets/registration-invite-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 3.5rem;
    height: 3.5rem;
}

.inviteTitle {
    line-height: 140%;
}

.titleHighlight {
    display: inline-block;
    position: relative;
    z-index: 0;
}

.titleHighlight:before {
    content: "";
    background: url("/assets/text-highlight.png") no-repeat center center;
    background-size: 100% 60px;
    width: 106%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: -1;
    transform: translate(-4%, 0);
}

.noBreak {
    white-space: nowrap;
}
