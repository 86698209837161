@import "../../_common.scss";


.brandLogo {
    display: inline-block;
    width: 80px;
    height: 24px;
    background-image: url("/logo.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.betaLabel {
    user-select: none;
    background-color: $primary;
    color: white;
    margin-left: 0.5rem;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 0.8rem;
    padding: 0.2rem 0.3rem 0.2rem 0.3rem;
    text-transform: uppercase;
    border-radius: 0.4rem;
}

.inverted {
    .brandLogo {
        filter: invert(100%);
    }

    .betaLabel {
        background-color: white;
        color: $primary;
    }
}

.large {
    .brandLogo {
        width: 120px;
        height: 25px;
    }

    .betaLabel {
        margin-left: 1rem;
        font-size: 1rem;
        padding: 0.45rem 0.3rem 0.35rem 0.3rem;
    }
}
