@import "bulma/sass/utilities/_all.sass";
@import "../../_common.scss";


.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.left,
.right {
    padding: 4.5rem;
    min-height: 56rem;
    font-size: 1.15rem;
}

.left {
    color: white;
    border-right: 4px solid #f8f8f8;
    background-color: $primary;
    background-image: url("../../assets/welcome-bg.png");
    background-position: center;
    background-size: cover;
    border-radius: 1.5rem 0 0 1.5rem;
}

.right {
    position: relative;
    background: white;
    border-left: 4px solid #f8f8f8;
    border-radius: 0 1.5rem 1.5rem 0;
}

@include mobile {
    .container {
        display: block;
        margin: 0;
    }

    .left,
    .right {
        border: none;
        border-radius: initial;
        min-height: initial;
    }
}
