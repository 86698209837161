.placeholder {
    border: none;
    margin: 0;
    padding: 0;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;

    .valueRow > div {
        height: auto;
    }
}

.valueText {
    font-size: 5rem;
    line-height: 1;
}