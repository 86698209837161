// /* Theme style (colors & sizes) */
@import "theme-default";

@import "node_modules/bulma-responsive-tables/bulma-responsive-tables";
@import "node_modules/bulma/bulma";
@import "@creativebulma/bulma-divider";
@import "@creativebulma/bulma-tooltip";
@import "bulma-steps-component/bulma-steps.sass";

/* Mixins */
@import "mixins";

// /* Theme components */
@import "dashboard";
@import "aside";
@import "title-bar";
@import "hero-bar";
@import "card";

.is-dotted-link {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-position: from-font;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f8f8f8;
  display: flex;
  flex: 1;
  flex-direction: column;
}

html.has-aside-expanded #root {
  min-height: calc(100vh - #{$navbar-height});
}

#legal-banner {
  @include transition(left);
}

@include desktop() {
  html.has-aside-expanded #legal-banner {
    left: $aside-width;
  }
}

html.has-aside-expanded.has-aside-mobile-expanded #legal-banner {
  left: $aside-width;
}

// Remove right margins that move content out of view when setting $navbar-breakpoint: 0 to remove the hamburger menu
.navbar > .container .navbar-brand,
.container > .navbar .navbar-brand {
  margin-left: 0;
}

.navbar > .container .navbar-menu,
.container > .navbar .navbar-menu {
  margin-right: 0;
}

// larger modals on bigger screens
@media screen and (min-width: 769px) {
  .modal-content.large {
    width: 70% !important;
    max-width: 1024px;
  }
}

// NOTE: seems to be required for modal-fx to work in prod mode
.modal {
  display: flex;
}

button.button.is-ghost {
  height: unset;
  padding: 0;
  vertical-align: unset;

  &:focus {
    border-color: transparent;
    box-shadow: none;
  }

  &:disabled {
    box-shadow: none;
    border: none;
  }
}
