@import "bulma/sass/utilities/_all.sass";
@import "../../../../common.scss";


.checkmark {
    opacity: 0.5;
    margin-top: 0.2rem;
    margin-right: 1rem;
}

ul.featureList {
    list-style-type: square;
    list-style-position: inside;
    margin-left: -0.5rem;

    li::marker {
        color: $primary;
    }
}

.servicesCard {
    background-color: white;

    @include desktop() {
        background-color: #e8f8fd;
    }
}