.container {
    position: relative;
    width: 234px;
    height: 28px;
}

.bar {
    position: absolute;
    top: 0;
    background-color: #0077ff88;
    width: 28px;
    height: 28px;
    animation-name: bounce_bars;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    transform: scale(0.3);
    border-radius: 50%;
}

@keyframes bounce_bars {
    0% {
        transform: scale(1);
        background-color: #0077ff88;
    }
    100% {
        transform: scale(0.3);
        background-color: #0077ff00;
    }
}
