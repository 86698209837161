.placeholder {
    border: none;
    margin: 0;
    padding: 0;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.largeText {
    height: 51px !important;
}

table.transactionsTable {
    thead {
        tr {
            text-align: center;

            th.timestampCol {
                text-align: left;
                width: 18rem;
            }

            th.typeCol {
                text-align: left;
            }

            th.amountCol {
                width: 10rem;
            }
        }
    }

    tbody {
        tr {
            text-align: center;

            td.timestampCol {
                text-align: left;
            }

            td.typeCol {
                text-align: left;
            }
        }
    }
}
