@import "bulma/sass/utilities/_all.sass";

.video {
    padding: 0;
    max-width: 25rem;
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;

    video {
        pointer-events: none;
    }
}

@include mobile() {
    .downloadSection {
        text-align: center;
    }
}
