@import "bulma/sass/utilities/_all.sass";

.outerContentArea {
    border: 2px solid transparent;
    display: flex;
    height: 11rem;
}

.innerContentArea {
    padding: 1rem;
}

@include mobile {
    .outerContentArea {
        height: auto;
    }
}
