:root {
  --legal-banner-height: 0;
}

html {
  min-height: 100%;
}

html, body {
  overflow-x: hidden;
}

html.modal-open {
  overflow: hidden;
}

body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fieldMessage {
  min-height: 1.2rem;
}

.fieldMessageDouble {
  min-height: 2.4rem;
}

.no-user-select {
  user-select: none;
}