@import 'bulma/sass/utilities/_all.sass';


.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.formContainer {
    background: white;
    border-radius: 10px;
}

.dialog {
    padding: 4.5rem;
    font-size: 1.15rem;
}

@include mobile {
    .container {
        display: block;
        margin-top: 3rem;
    }

    .left {
        padding-bottom: 0rem;
    }
}
